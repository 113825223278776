function App() {
  return (
    <>
      <div className='container text-center'>
        <img
          src='vs512x512.png'
          alt='Vidya Sampkar'
          className='img img-fluid'
        />

        <h3>connecting students, parents and schools</h3>
      </div>
    </>
  );
}

export default App;
